if (document.getElementById('js-learn-plus')) {

    let plusBtn = document.getElementById('js-learn-plus');
    let container = document.getElementById('js-learn-container');
    let text = document.getElementById('learn-text');
    
    plusBtn.addEventListener('click', function() {
        // document.getElementById("search").style.height = "100%";
        this.classList.toggle('toggled');
        container.classList.toggle('overlay-visible')
        text.classList.toggle('visible')
    }, false);
    
};