'use strict';
require('./ol');
import Proj4 from 'proj4';
import {findAncestor, toggle} from './utils';

class Map
{
	constructor(mapElementID, options = {})
	{
		Proj4.defs([
			[
				'EPSG:4326',
				'+proj=longlat +datum=WGS84 +no_defs'
			],
			[
				'EPSG:3011',
				'+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
			]
		]);

		this.projectionSweden = new Proj4.Proj('EPSG:3011');
		this.projectionWorld = new Proj4.Proj('EPSG:4326');

		this.mapElementID = mapElementID;
		this.markerElements = document.querySelectorAll('.marker-container .marker');
		this.ol = window.ol;
		this.options = Object.assign({}, {
				center: [189244.38985113383, 6627322.711811554],
				zoom: 10,
			},
			options
		);
	}

	renderMap()
	{
		const wmsSource = new this.ol.source.ImageWMS({
			url: 'https://norrtaljekarta.norrtalje.se/myCartaServerExt/Bakgrundskarta.wms',
			params: {
				LAYERS: 'Bakgrundskarta',
			},
			attributions: ['Norrtälje kommun, Lantmäteriet, Länsstyrelsen & Skogsstyrelsen'],
			ratio: 1,
			hidpi: false
		});

		const UserLocationControl = this.userLocationControl();

		this.map = new this.ol.Map({
			interactions: this.ol.interaction.defaults({
				altShiftDragRotate: false,
				pinchRotate: false
			}),
			controls: [
				new this.ol.control.Attribution(),
				new this.ol.control.Zoom(),
				new this.ol.control.FullScreen(),
				new UserLocationControl(),
			],
			target: this.mapElementID,
			logo: '',
			layers: [
				new this.ol.layer.Image({
					lyrId: 0,
					name: 'Bakgrundskarta',
					title: 'Bakgrundskarta',
					source: wmsSource,
				})
			],
			view: new this.ol.View({
				projection: new this.ol.proj.Projection({
					code: 'EPSG:3011',
					extent: [-1411202.82102843, 6100510.0668848, 1638298.12150511, 7646348.64089808]
				}),
				center: this.options.center,
				zoom: this.options.zoom,
			})
		});

		this.renderMarkers();
		this.markerCategoryFilter();
		this.renderUserLocationFeature();

		const selectors = {
			markerIcon: '.marker-icon',
			markerPopup: '.marker-popup',
		};

		this.map.on('click', (event) => {
			const element = event.originalEvent.srcElement.parentElement;
			if (element.classList.contains('marker-icon'))
			{
				const popupElement = element.parentElement.querySelector(selectors.markerPopup);
				if (popupElement) // Toggle popup
				{
					if (popupElement.classList.contains('visible')) {
						popupElement.classList.remove('visible');
					}
					else {
						const visiblePopup = document.querySelector(selectors.markerPopup + '.visible');
						if (visiblePopup) { // If another popup is already visible hide it
							visiblePopup.classList.remove('visible');
						}
						popupElement.classList.add('visible');
					}
				}
			}
			else
			{
				const markerPopup = findAncestor(event.originalEvent.srcElement, 'marker-popup');
				if (markerPopup && markerPopup.dataset.href) // Go to popup location if clicked somewhere inside the popup
				{
					window.location = markerPopup.dataset.href;
				}
				else if(!markerPopup) // If a click is not on a popup or marker-icon, hide potential visible popup
				{
					const visiblePopup = document.querySelector(selectors.markerPopup + '.visible');
					if (visiblePopup) {
						visiblePopup.classList.remove('visible');
					}
				}
			}
		});
	}

	userLocationControl()
	{
		const _this = this;

		return (function (Control) {
			function UserLocationControl(opts) {
				const options = opts || {};

				const button = document.createElement('button');
				button.title = 'Your location';

				const element = document.createElement('div');
				element.className = 'user-geolocation-control ol-unselectable ol-control';
				element.appendChild(button);

				Control.call(this, {
					element: element,
					target: options.target
				});

				button.addEventListener('click', this.handleRotateNorth.bind(this), false);
			}

			if ( Control ) {
				UserLocationControl.__proto__ = Control;
			}

			UserLocationControl.prototype = Object.create( Control && Control.prototype );
			UserLocationControl.prototype.constructor = UserLocationControl;

			UserLocationControl.prototype.handleRotateNorth = function handleRotateNorth () {
				_this.geolocation.setTracking(true);
				const coordinates = _this.geolocation.getPosition();
				if (coordinates) {
					_this.map.getView().animate({
						center: new Proj4(_this.projectionWorld, _this.projectionSweden, coordinates),
						duration: 1000
					});
				}
			};
			return UserLocationControl;
		}(this.ol.control.Control));
	}

	renderUserLocationFeature()
	{
		const geolocation = new this.ol.Geolocation({
			trackingOptions: {
				enableHighAccuracy: true
			},
			projection: this.map.getView().getProjection()
		});
		geolocation.setTracking(true);

		const positionFeature = new this.ol.Feature();

		positionFeature.setStyle(new this.ol.style.Style({
			image: new this.ol.style.Circle({
				radius: 6,
				fill: new this.ol.style.Fill({
					color: '#3399CC'
				}),
				stroke: new this.ol.style.Stroke({
					color: '#fff',
					width: 2
				})
			})
		}));

		geolocation.on('change:position', () => {
			const coordinates = geolocation.getPosition();
			if (coordinates) {
				const convertedCoordinates = new Proj4(this.projectionWorld, this.projectionSweden, coordinates);
				positionFeature.setGeometry(new this.ol.geom.Point(convertedCoordinates));
			}
			else {
				positionFeature.setGeometry(null);
			}
		});

		new this.ol.layer.Vector({
			map: this.map,
			source: new this.ol.source.Vector({
				features: [positionFeature]
			})
		});

		this.geolocation = geolocation;
	}

	renderMarkers()
	{
		const coordinates = [];

		this.markerElements.forEach((markerElement) => {
			try {
				const coordinate = new Proj4(this.projectionWorld, this.projectionSweden, [parseFloat(markerElement.dataset.posY), parseFloat(markerElement.dataset.posX)]);
				const marker = new this.ol.Overlay({
					position: coordinate,
					positioning: 'center-center',
					element: markerElement,
					stopEvent: false
				});

				this.map.addOverlay(marker);
				if (markerElement.dataset.category) {
					coordinates.push(coordinate);
				}

			} catch(error) {
				console.log('Failed to add marker', markerElement, error);
			}
		});

		//Center map around markers
		if (coordinates.length > 0) {
			this.map.getView().fit(this.ol.extent.boundingExtent(coordinates), { maxZoom: 12 });
		}
	}

	markerCategoryFilter()
	{
		if (document.getElementById('js-tab'))
		{
			const _this = this;
			const showAll = document.getElementById('js-activate-all');
			const showNone = document.getElementById('js-deactivate-all');
			const categories = document.querySelectorAll('.cat-container');

			categories.forEach((category) => {
				category.addEventListener('click', function() {
					categories.forEach((cat) => {
						if (this === cat) {
							cat.classList.add('active');
							_this.activeCategory = cat.dataset.category;
						} else {
							cat.classList.remove('active');
						}
					});
					_this.filterMarkers();
				});
			});

			showAll.addEventListener('click',  () => {
				categories.forEach((category) => {
					category.classList.add('active');
				});
				this.activeCategory = 'any';
				this.filterMarkers();
			});

			showNone.addEventListener('click',  () => {
				categories.forEach((category) => {
					category.classList.remove('active');
				});
				this.activeCategory = 'none';
				this.filterMarkers();
			});

			const activeCategory = document.querySelectorAll('.cat-container.active');
			if (activeCategory.length === 0) {
				this.activeCategory = '';
			} else {
				this.activeCategory = activeCategory.length === 1 ? activeCategory[0].dataset.category : 'any';
			}
			this.filterMarkers();
		}
	}

	static toggleMarker(element, show)
	{	
		if(element.dataset.category === 'premises') {
			element.style.visibility = 'visible';
			console.log(element);
		}
		else {
			element.style.visibility = show ? 'visible' : 'hidden';
		}
	}

	filterMarkers()
	{
		this.markerElements.forEach((marker) => {
			this.constructor.toggleMarker(marker, this.activeCategory === 'any' || marker.dataset.category === this.activeCategory);
		});
	}
}

document.addEventListener('DOMContentLoaded', function()
{
	const mapElement = document.getElementById('map');
	if (mapElement)
	{
		const map = new Map(mapElement);
		map.renderMap();
	}
});