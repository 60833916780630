window.$ = require('jquery');
window.jQuery = window.$;

if (document.getElementById('js-tab')) {

    $( "#js-tab" ).click(function() {
        $( "#js-nav-inner" ).slideToggle( "slow", function() {
          // Animation complete.
        });
      });
    
}
