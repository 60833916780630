if (document.getElementById('js-info-btn')) {

let infoBtn = document.getElementById('js-info-btn');
let closeInfoBtn = document.getElementsByClassName('close-arrow');

infoBtn.addEventListener('click', function() {
    document.getElementById("info").style.opacity = "1";
    document.getElementById("info").style.visibility = "visible";
}, false);


for (var i = 0; i < closeInfoBtn.length; i++) {
    // console.log(closeInfoBtn);
    closeInfoBtn[i].addEventListener('click', function() {
        document.getElementById("info").style.opacity = "0";
        document.getElementById("info").style.visibility = "hidden";
    });
}

}