'use strict';

// Show an element
export const show = function (element) {
	element.style.display = 'block';
};

// Hide an element
export const hide = function (element) {
	element.style.display = 'none';
};

// Toggle element visibility
export const toggle = function (element) {

	// If the element is visible, hide it
	if (window.getComputedStyle(element).display === 'block') {
		hide(element);
		return;
	}

	// Otherwise, show it
	show(element);

};

export const findAncestor = (el, cls) => {
	while ((el = el.parentElement) && !el.classList.contains(cls));
	return el;
};