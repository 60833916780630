'use strict';

class SearchHandler
{
    constructor()
    {
        this.searchSubmitted = false;
        this.previousQuery = '';
        this.previousSearcHTML = '';
        this.timer = null;
        this.searchInput = document.querySelector('.search-input');
        this.searchResultsContainer = document.querySelector('.search-results-container');
        this.resultsCountContainer = document.querySelector('.search-results-text');
        this.queryMinLength = 3;

        ['change', 'keyup'].forEach((event) => {
            this.searchInput.addEventListener(event, () => {
                this.handleSearch();
            });
        });
    }

    handleSearch()
    {
        const query = this.searchInput.value.trim();

        if (this.searchSubmitted || this.previousQuery === query)
        {
            return;
        }
        this.previousQuery = query;
        clearTimeout(this.timer);

        if ( query.length >= this.queryMinLength )
        {
            this.timer = setTimeout(() => {
                fetch(window.ajaxurl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                    },
                    body: `action=submit_search&searchTerm=${query}`,
                    credentials: 'same-origin'
                }).then(response => {
                    return response.json();
                }).then((responseData) => {
                    this.renderSearchResults(responseData.data.html, responseData.data.count, query);
                });
                return false;
            }, 500);
        }
        else
        {
            this.renderSearchResults('', 0, query);
        }
    }

    renderSearchResults(html, resultCount, query)
    {
        this.previousSearcHTML = html;
        this.searchResultsContainer.innerHTML = html;
        this.resultsCountContainer.innerHTML = query.length >= this.queryMinLength ? `Hittade ${resultCount} träff` + (resultCount === 1 ? '' : 'ar') + ` för "${query}"` : '';
    }
}

if (document.getElementById('js-search'))
{
    const searchBtn = document.getElementById('js-search');
    const closeSearchBtn = document.getElementById('js-search-close');

    searchBtn.addEventListener('click', function() {
        document.getElementById('search').style.height = '100%';
        document.querySelector('.search-input').focus();
    }, false);

    closeSearchBtn.addEventListener('click', function() {
        document.getElementById('search').style.height = '0%';
    }, false);

    document.addEventListener('DOMContentLoaded', function()
    {
        new SearchHandler();
    });
}


